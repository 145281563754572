<template>
  <v-container
    fluid
    :class="$vuetify.breakpoint.smAndUp ? 'py-5 px-10' : 'pa-0'"
  >
    <v-card
      flat
      :class="$vuetify.breakpoint.smAndUp ? 'pa-5' : 'transparent pa-0'"
    >
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        text
        class="px-0 mb-10 grey--text text--darken-2"
        @click="$emit('changePageIndex', 20)"
      >
        <v-icon size="20"> mdi-chevron-left </v-icon>
        돌아가기
      </v-btn>
      <v-card-title
        v-if="$vuetify.breakpoint.smAndUp"
        class="pa-2 headline font-weight-bold"
      >
        기본정보
      </v-card-title>
      <v-card-text :class="$vuetify.breakpoint.smAndUp ? 'pt-12' : 'pa-0'">
        <v-row>
          <!-- 프로필 이미지 -->
          <v-col sm="5" cols="4" class="d-flex align-center">
            <v-avatar
              :size="$vuetify.breakpoint.smAndUp ? '100' : '72'"
              class="mx-auto"
              :color="imgUpload ? 'rgb(189,189,189,0.5)' : 'surface'"
              @mouseover="imgUpload = true"
              @mouseleave="imgUpload = false"
            >
              <v-img
                :src="imgUploadFile ? imgUploadURL : userData.photoURL"
                :style="profileColor()"
              >
                <v-btn
                  v-if="imgUpload"
                  x-small
                  class="ma-auto"
                  @click="$refs.uploader.click()"
                >
                  업로드
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  @change="onFileChanged"
                />
              </v-img>
            </v-avatar>
          </v-col>
          <!-- 기본 정보 -->
          <v-col sm="7" cols="8">
            <!-- DiaplayName -->
            <div
              :class="
                $vuetify.breakpoint.smAndUp
                  ? 'd-flex pl-3 py-2'
                  : 'd-flex pl-0 py-0'
              "
              style="max-width: 150px"
            >
              <div v-if="displayNameEdit == false">
                <span class="title font-weight-medium black--text">
                  {{ userData.displayName }}
                </span>
                <v-icon
                  size="16"
                  class="mb-2 pl-1"
                  @click="displayNameEdit = true"
                >
                  mdi-pencil
                </v-icon>
              </div>
              <div v-else>
                <v-text-field
                  v-model="displayName"
                  dense
                  placeholder="성함을 입력하세요."
                  append-icon="mdi-close"
                  @click:append="displayNameEdit = false"
                  :rules="[(v) => !!v || '필수 항목입니다.']"
                  required
                />
              </div>
            </div>
            <!-- Email -->
            <div class="d-flex py-2" v-if="$vuetify.breakpoint.smAndUp">
              <!-- 카카오계정 -->
              <v-avatar
                v-if="userProvider == 'Kakao'"
                size="24"
                color="#fee500"
              >
                <v-img :src="require('@/assets/logos/auth/kakao.png')" />
              </v-avatar>
              <!-- 네이버계정 -->
              <v-avatar
                v-else-if="userProvider == 'Naver'"
                size="24"
                color="#00c93b"
              >
                <v-img :src="require('@/assets/logos/auth/naver.png')" />
              </v-avatar>
              <!-- 구글계정 -->
              <v-avatar
                v-else-if="userProvider == 'Google'"
                size="24"
                color="surface"
              >
                <v-img :src="require('@/assets/logos/auth/google.png')" />
              </v-avatar>
              <!-- 이메일 로그인 -->
              <v-icon v-else size="20" color=""> mdi-email </v-icon>
              <span class="subtitle-1 pl-4">
                {{ userData.email }}
              </span>
            </div>
            <!-- PhoneNumber -->
            <div
              :class="
                $vuetify.breakpoint.smAndUp ? 'd-flex py-2' : 'd-flex py-0'
              "
            >
              <v-icon
                v-if="$vuetify.breakpoint.smAndUp"
                size="20"
                :class="$vuetify.breakpoint.smAndUp ? 'pr-4' : 'pr-1'"
              >
                mdi-cellphone
              </v-icon>
              <div v-if="phoneNumberEdit == false">
                <span class="subtitle-1 pr-2 black--text">
                  {{
                    userData.phoneNumber
                      ? userData.phoneNumber.replace(
                          /^(\d{0,3})(\d{0,4})(\d{0,4})$/g,
                          "$1-$2-$3",
                        )
                      : ""
                  }}
                </span>
                <v-icon size="16" class="mb-1" @click="phoneNumberEdit = true">
                  mdi-pencil
                </v-icon>
              </div>
              <div v-else>
                <v-text-field
                  v-model="phoneNumber"
                  dense
                  placeholder="연락처를 입력하세요."
                  append-icon="mdi-close"
                  @click:append="phoneNumberEdit = false"
                  required
                  :rules="[
                    (v) => v.length == 11 || `${v.length} / 11`,
                    (v) => !!v || '필수 항목입니다.',
                  ]"
                />
              </div>
            </div>
            <!-- Email Mobile-->
            <div class="d-flex py-0" v-if="$vuetify.breakpoint.smAndDown">
              <span class="subtitle-2">
                {{ userData.email }}
              </span>
            </div>
          </v-col>
        </v-row>
        <!-- 추가정보 -->
        <v-card
          flat
          :class="
            $vuetify.breakpoint.smAndUp
              ? 'pt-12 px-10'
              : 'pt-10 pb-7 px-5 transparent'
          "
        >
          <v-card-title
            class="pa-0"
            :class="
              $vuetify.breakpoint.smAndUp
                ? 'subtitle-1 font-weight-bold'
                : 'subtitle-2 mx-auto grey--text text--darken-1'
            "
          >
            마케팅 약관
          </v-card-title>
          <v-card-actions
            :class="
              $vuetify.breakpoint.smAndUp ? 'ml-3 pa-0 pt-4' : 'px-0 pt-4'
            "
          >
            <v-icon
              class="mx-2"
              :color="marketing ? 'primary' : '#858585'"
              @click="marketing ? (marketing = false) : (marketing = true)"
            >
              mdi-check
            </v-icon>
            <span class="black--text">
              마케팅 활용 및 광고성 정보 수신 동의
            </span>
            <v-btn
              text
              x-small
              class="caption px-1 align-center"
              color="primary"
              style="margin-top: -1px"
              :href="'https://docs.madeall3d.com/terms/marketing'"
              target="_blank"
            >
              자세히
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
      <!-- 저장 -->
      <v-card-actions
        :class="$vuetify.breakpoint.smAndUp ? 'mb-2 mx-12' : 'mb-3 mx-5'"
      >
        <v-spacer />
        <v-btn
          color="primary"
          class="px-3"
          depressed
          :loading="saveBtnLoading"
          @click="save"
          :disabled="saveValid"
        >
          변경사항 저장
        </v-btn>
      </v-card-actions>
      <!-- 비밀번호 변경 -->
      <div v-if="userProvider == 'Firebase'" class="pa-0">
        <v-divider
          :class="$vuetify.breakpoint.smAndUp ? 'mx-12' : 'mx-5'"
        ></v-divider>
        <v-card
          flat
          :class="
            $vuetify.breakpoint.smAndUp ? 'px-12 py-2' : 'px-4 py-4 transparent'
          "
        >
          <v-card-text class="pt-8 pb-0">
            <v-text-field
              v-model="newPwd1"
              style="width: 260px"
              dense
              clearable
              label="신규 비밀번호"
              :append-icon="newPwdShow2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="newPwdShow2 = !newPwdShow2"
              :type="newPwdShow2 ? 'text' : 'password'"
              :rules="[
                (v) => v.length >= 8 || `${v.length} / 8`,
                (v) => v.length <= 50 || `${v.length} / 50`,
              ]"
            />
            <v-text-field
              v-model="newPwd2"
              style="width: 260px"
              dense
              clearable
              label="신규 비밀번호 재입력"
              :append-icon="newPwdShow2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="newPwdShow2 = !newPwdShow2"
              :rules="[newPwd1 === newPwd2 || '비밀번호가 일치하지 않습니다.']"
              :type="newPwdShow2 ? 'text' : 'password'"
            />
          </v-card-text>
          <v-card-actions class="px-4 pb-7">
            <v-btn color="primary" @click="changePwd" :disabled="newPwd2 == ''">
              비밀번호 변경</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
      <!-- 모바일용 학생정보 기업정보 탭 -->
      <div v-if="$vuetify.breakpoint.smAndDown" class="pa-0">
        <v-divider class="mx-5"></v-divider>
        <div class="py-5 px-5">
          <v-btn
            block
            depressed
            class="my-3 grey--text text--darken-2 py-2 font-weight-regular"
            @click="$emit('changePageIndex', 11)"
            ><v-icon class="pr-1">mdi-account-school</v-icon> 학생정보<v-icon
              class="pl-2"
              >mdi-chevron-right</v-icon
            ></v-btn
          >
          <v-btn
            block
            depressed
            class="my-3 grey--text text--darken-2 py-2 font-weight-regular"
            @click="$emit('changePageIndex', 12)"
            ><v-icon class="pr-1">mdi-domain</v-icon> 기업정보<v-icon
              class="pl-2"
              >mdi-chevron-right</v-icon
            ></v-btn
          >
        </div>
      </div>
      <!-- 회원탈퇴 -->
      <v-divider
        :class="$vuetify.breakpoint.smAndUp ? 'mx-12' : 'mx-5'"
      ></v-divider>
      <v-card-actions :class="$vuetify.breakpoint.smAndUp ? 'pr-10' : 'pr-5'">
        <v-spacer />
        <v-btn
          depressed
          color="rounded-sm white"
          text
          small
          @click="dialogDelete = true"
          class="px-2 caption"
        >
          회원탈퇴 <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
        <v-dialog v-model="dialogDelete" max-width="320px">
          <v-card class="rounded-xl text-center pa-2" flat>
            <v-card-title class="title font-weight-bold justify-center">
              정말로 탈퇴하시겠습니까?
            </v-card-title>
            <v-card-subtitle class="ma-0">
              주문 내역 등 모든 데이터가 삭제됩니다. <br />
              이 동작은 실행 취소할 수 없습니다.
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="rounded-lg" depressed @click="dialogDelete = false">
                취소
              </v-btn>
              <v-btn
                color="error"
                class="rounded-lg"
                depressed
                @click="deleteItemConfirm"
                :loading="deleteBtnLoading"
              >
                삭제
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import firestorageUtils from "@/utils/firestorage";
import Compressor from "compressorjs";
export default {
  name: "MyPage-UserInfo",
  props: { userData: Object, saveBtnLoading: Boolean },
  data() {
    return {
      valid: false,

      imgUpload: false,
      imgUploadFile: "",
      imgUploadURL: "",

      userProvider: "",

      displayName: this.userData.displayName,
      displayNameEdit: false,
      phoneNumber: this.userData.phoneNumber,
      phoneNumberEdit: false,
      marketing: this.userData.agree.marketing,

      newPwd1: "",
      newPwdShow1: false,
      newPwd2: "",
      newPwdShow2: false,

      dialogDelete: false,
      deleteBtnLoading: false,
    };
  },
  created() {
    if (this.userData.uid.split(":")[0] == "kakao") {
      this.userProvider = "Kakao";
    } else if (this.userData.uid.split(":")[0] == "naver") {
      this.userProvider = "Naver";
    } else if (
      this.$store.getters["auth/user/GET_USER"].providerData &&
      this.$store.getters["auth/user/GET_USER"].providerData[0].providerId ==
        "google.com"
    ) {
      this.userProvider = "Google";
    } else {
      this.userProvider = "Firebase";
    }
  },
  computed: {
    saveValid() {
      var valid = false;
      valid =
        this.imgUploadURL ||
        this.phoneNumberEdit ||
        this.displayNameEdit ||
        this.marketing != this.userData.agree.marketing;
      return !valid;
    },
  },
  methods: {
    profileColor() {
      var opacity = "";
      opacity = this.imgUpload ? "opacity: 0.8;" : "";
      var color = "";
      switch (this.userProvider) {
        case "Kakao":
          color = "border: 4px solid #fee500;";
          break;
        case "Naver":
          color = "border: 4px solid #00c93b;";
          break;
        case "Google":
          color = "border: 4px solid surface;";
          break;
        case "Firebase":
          color = "border: 4px solid primary;";
          break;
      }
      return opacity + color;
    },
    // 기본 정보 프로필 이미지 Input
    onFileChanged(e) {
      this.imgUploadFile = e.target.files[0];
      if (this.imgUploadFile.type.split("/")[0] != "image")
        this.$toasted.global.error("이미지 파일만 등록 가능합니다.");
      this.imgUploadURL = URL.createObjectURL(this.imgUploadFile);
    },
    // 기본정보 저장
    async save() {
      if (this.displayNameEdit)
        if (!this.displayName)
          return this.$toasted.global.error("이름을 작성해주세요.");
      if (this.phoneNumberEdit)
        if (!this.phoneNumber)
          return this.$toasted.global.error("연락처를 작성해주세요.");
      // 프로필 이미지 Upload
      if (this.imgUploadFile) {
        const that = this;
        new Compressor(this.imgUploadFile, {
          quality: 0.8,
          width: 300,
          convertSize: 20000,
          success(result) {
            that.imgUploadFile = result;
          },
        });
        const serverResult = await firestorageUtils.uploadFile({
          file: this.imgUploadFile,
          path: `Users/${this.userData.uid}/profileImage/${this.imgUploadFile.name}`,
        });
        this.userData.photoURL = serverResult.downloadURL;
      }
      const saveData = {
        photoURL: this.userData.photoURL,
        displayName: this.displayName,
        phoneNumber: this.phoneNumber,
        agree: { marketing: this.marketing },
      };
      this.$emit("saveData", saveData);
      this.$axios.patch("channelTalk/upsertProfile", {
        uid: this.userData.uid,
        name: this.displayName,
        phoneNumber: this.phoneNumber,
        email: this.userData.email,
        photoURL: this.userData.photoURL,
      });

      this.imgUploadFile = "";
      this.imgUploadURL = "";
      this.phoneNumberEdit = "";
      this.displayNameEdit = "";
    },
    // 비밀번호 변경
    changePwd() {
      this.$firebase
        .auth()
        .currentUser.updatePassword(this.newPwd2)
        .then(() => {
          this.$toasted.global.success("비밀번호가 변경되었습니다.");
          this.newPwd1 = "";
          this.newPwd2 = "";
        })
        .catch((e) => {
          this.$toasted.global.error("보안을 위해 새로 로그인 후 시도해주세요");
          console.log(e);
        });
    },
    // 회원탈퇴
    async deleteItemConfirm() {
      this.deleteBtnLoading = true;
      await this.$firebase
        .auth()
        .currentUser.delete()
        .catch((e) => {
          console.log(e);
          this.$toasted.global.error(
            "인증이 만료되었습니다. 탈퇴를 위하여 다시 로그인 해 주세요",
          );
          this.$router.push("/login");
        })
        .then(() => {
          this.$axios.patch("channelTalk/deleteUser", {
            uid: this.userData.uid,
          });
          this.$store.dispatch("auth/user/SET_LOGOUT", "Home");
        });
    },
  },
};
</script>

<style></style>

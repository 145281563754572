<template>
  <v-container
    fluid
    :class="$vuetify.breakpoint.smAndUp ? 'py-5 px-10' : 'pa-0'"
  >
    <v-card
      flat
      :class="$vuetify.breakpoint.smAndUp ? 'pa-5' : 'transparent pa-0'"
    >
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        text
        class="px-0 mb-10 grey--text text--darken-2"
        @click="$emit('changePageIndex', 10)"
      >
        <v-icon size="20"> mdi-chevron-left </v-icon>
        돌아가기
      </v-btn>
      <v-card-title
        :class="
          $vuetify.breakpoint.smAndUp
            ? 'pa-2 headline font-weight-bold'
            : 'title font-weight-bold'
        "
      >
        학생정보
      </v-card-title>
      <v-card-text :class="$vuetify.breakpoint.smAndUp ? 'px-7 pt-8' : 'px-7'">
        <v-autocomplete
          v-model="userData.schoolInfo.school"
          style="width: 260px"
          class="py-7"
          :items="schoolList"
          cache-items
          single-line
          hide-no-data
          hide-details
          dense
          clearable
          label="학교를 선택하세요."
        />
        <v-text-field
          v-model="userData.schoolInfo.studentId"
          style="width: 260px"
          dense
          clearable
          label="학번을 입력해주세요."
        />
      </v-card-text>
      <!-- 저장 -->
      <v-card-actions class="mb-15 px-7">
        <v-btn
          color="primary"
          class="px-3"
          depressed
          :loading="saveBtnLoading"
          @click="save"
          :disabled="saveValid"
        >
          저장
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import constants from "@/utils/constants";
export default {
  name: "MyPage-StudentInfo",
  props: { userData: Object, originalData: Object, saveBtnLoading: Boolean },
  data() {
    return {
      schoolList: constants.schoolList,
    };
  },
  created() {},
  computed: {
    saveValid() {
      this.text_clear();
      var valid = false;
      if (
        Object.entries(this.userData.schoolInfo).toString() !=
        Object.entries(this.originalData.schoolInfo).toString()
      ) {
        valid = true;
      } else {
        valid = false;
      }
      return !valid;
    },
  },
  methods: {
    text_clear() {
      if (this.userData.schoolInfo.school == null)
        this.userData.schoolInfo.school = "";
      if (this.userData.schoolInfo.studentId == null)
        this.userData.schoolInfo.studentId = "";
    },
    // 기본정보 저장
    async save() {
      if (this.userData.schoolInfo.school) {
        if (!this.userData.schoolInfo.studentId)
          return this.$toasted.global.error("정보를 모두 작성해주세요.");
      } else {
        if (this.userData.schoolInfo.studentId)
          return this.$toasted.global.error("학번도 삭제해 주세요.");
      }
      if (this.userData.schoolInfo.studentId != "") this.userData.userClass = 1;
      else if (this.userData.companyInfo.companyId != "")
        this.userData.userClass = 2;
      else this.userData.userClass = 0;
      const saveData = {
        userClass: this.userData.userClass,
        schoolInfo: {
          school: this.userData.schoolInfo.school,
          studentId: this.userData.schoolInfo.studentId,
        },
      };
      this.$emit("saveData", saveData);

      this.$toasted.global.success("수정 완료!");
    },
  },
};
</script>

<style></style>

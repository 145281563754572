<template>
  <v-dialog
    class="elevation-0"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    scrollable
    max-width="480px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-5" small @click="open()" v-bind="attrs" v-on="on" icon>
        <v-icon> mdi-list-box-outline </v-icon>
      </v-btn>
    </template>

    <v-card flat class="rounded-xl">
      <!-- 본문 영역 -->
      <v-card-text class="mt-3">
        <v-container>
          <!-- 좌측 기본정보 주문정보 패널 -->
          <v-row>
            <v-col cols="12">
              <span class="highlight title font-weight-bold onSurface--text">
                작업 내역
              </span>
            </v-col>
            <v-col cols="4" md="2" align-self="center"> 성함 </v-col>
            <v-col cols="8" md="10">
              <span class="onSurface--text">
                {{ data.displayName }}
              </span>
            </v-col>
            <v-col
              v-if="this.$store.getters['auth/user/GET_CLAIMS'].level < 3"
              cols="4"
              md="2"
              align-self="center"
            >
              ID
            </v-col>
            <v-col
              v-if="this.$store.getters['auth/user/GET_CLAIMS'].level < 3"
              cols="8"
              md="10"
            >
              <span class="onSurface--text">
                {{ data.id }}
              </span>
              <v-btn
                v-clipboard:copy="data.id"
                v-clipboard:success="
                  () => {
                    onCopy();
                  }
                "
                v-clipboard:error="
                  () => {
                    onCopyError();
                  }
                "
                small
                color="grey lighten-1"
                class="ml-2"
                icon
              >
                <feather size="18" type="copy" />
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :loading="Loading"
                :headers="headers"
                :items="status"
                hide-default-footer
                disable-sort
              >
                <template v-slot:[`item.status`]="{ item }">
                  <span
                    class="ml-2 onSurface--text"
                    v-text="getStatusData(item.status).title"
                  />
                </template>
                <template v-slot:[`item.date`]="{ item }">
                  <span>
                    {{ item.date | moment("YYYY/MM/DD &nbsp;HH시mm분") }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <!-- 하단 취소 및 저장버튼 -->
      <v-card-actions class="pa-4 pt-0">
        <v-spacer></v-spacer>
        <!-- 화면 크기가 클 때만 취소 버튼으로 뜸 -->
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          class="rounded-lg"
          text
          @click="close"
          :ripple="false"
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import _ from "lodash";
import constants from "@/utils/constants";
export default {
  props: {
    data: Object,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  data() {
    return {
      dialog: false,
      Loading: false,
      headers: [
        {
          text: "진행사항",
          value: "status",
          align: "center",
        },
        {
          text: "일시",
          value: "date",
          align: "center",
        },
        {
          text: "담당자",
          value: "charge",
          align: "center",
        },
      ],
      status: [],
      editedItem: [],
      orderStatusItem: constants.orderStatusItem,
    };
  },
  methods: {
    onCopy: function () {
      this.$toasted.global.success("클립보드에 저장했습니다.");
    },
    onCopyError: function () {
      this.$toasted.global.error("복사를 실패했습니다.");
    },
    open() {
      for (var i = 0; i < this.data.statusChanged.updateCount; i++) {
        this.status[i] = this.data.statusChanged[i];
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    getStatusData(statusLevel) {
      return this.orderStatusItem[statusLevel];
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <page-appbar />
    <router-view />
    <!-- PC View -->
    <v-container
      v-if="$vuetify.breakpoint.smAndUp"
      class="ma-0 pa-0"
      fluid
      style="border-top: 1px solid #aaaaaa"
    >
      <v-container style="width: 960px; border-right: 1px solid #e1e2e6">
        <v-row>
          <v-col cols="3" class="pa-0">
            <mypage-sidebar
              :userData="userData"
              :pageIndex="pageIndex"
              @changePageIndex="changePageIndex"
            />
          </v-col>
          <v-col cols="9" class="pa-0">
            <my-orders v-if="pageIndex == 20" :userData="userData" />
            <user-info
              v-if="pageIndex == 10"
              :userData="userData"
              @saveData="saveData"
            />
            <student-info
              v-if="pageIndex == 11"
              :userData="userData"
              :originalData="originalData"
              @saveData="saveData"
            />
            <company-info
              v-if="pageIndex == 12"
              :userData="userData"
              :originalData="originalData"
              @saveData="saveData"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!-- Mobile View -->
    <v-container v-else fluid class="blue-grey lighten-5 pa-6">
      <v-card flat class="transparent" v-if="pageIndex == 20">
        <v-card class="d-flex rounded-lg" height="70px" @click="pageIndex = 10">
          <v-avatar size="50" class="my-auto ml-5">
            <v-img :src="userData.photoURL" />
          </v-avatar>
          <v-spacer />
          <div class="mr-3 my-auto">
            <div class="text-end">
              <span class="title mx-2" v-text="userData.displayName" />
              <span class="subtitle-2">고객님</span>
            </div>
            <div class="caption">
              {{ userData.email }}
            </div>
          </div>
          <v-icon class="my-auto mr-3"> mdi-chevron-right </v-icon>
        </v-card>
        <v-divider class="my-8"></v-divider>
        <v-card class="transparent" flat height="500px">
          <my-orders v-if="pageIndex == 20" :userData="userData" />
        </v-card>
      </v-card>
      <user-info
        v-if="pageIndex == 10"
        :userData="userData"
        @saveData="saveData"
        @changePageIndex="changePageIndex"
      />
      <student-info
        v-if="pageIndex == 11"
        :userData="userData"
        :originalData="originalData"
        @saveData="saveData"
        @changePageIndex="changePageIndex"
      />
      <company-info
        v-if="pageIndex == 12"
        :userData="userData"
        :originalData="originalData"
        @saveData="saveData"
        @changePageIndex="changePageIndex"
      />
    </v-container>

    <!-- <kakaotalk-floating-button /> -->
    <page-footer />
  </div>
</template>

<script>
import PageAppbar from "../../../components/Appbar/PageAppbar.vue";
// import KakaotalkFloatingButton from "../../../components/Assets/KakaotalkFloatingButton.vue";
import PageFooter from "../../../components/Footer/PageFooter.vue";
import MypageSidebar from "./MypageSidebar.vue";
import UserInfo from "./UserInfo.vue";
import setMeta from "@/utils/setMeta";
import StudentInfo from "./StudentInfo.vue";
import CompanyInfo from "./CompanyInfo.vue";
import MyOrders from "./MyOrders.vue";
import _ from "lodash";
import API from "@/API/auth.js";

export default {
  components: {
    PageAppbar,
    // KakaotalkFloatingButton,
    MypageSidebar,
    PageFooter,
    UserInfo,
    StudentInfo,
    CompanyInfo,
    MyOrders,
  },
  data() {
    return {
      pageIndex: 20,
      userData: {},
      saveBtnLoading: false,
    };
  },
  mounted() {
    setMeta({ title: "마이페이지", description: "회원 정보 및 주문 내역" });
    this.getUserData();
  },
  methods: {
    changePageIndex(index) {
      this.pageIndex = index;
    },
    async getUserData() {
      this.userData = await API.getStoreUserData();
      this.originalData = _.cloneDeep(this.userData);
    },
    async saveData(data) {
      this.saveBtnLoading = true;
      var saveUserData = "";
      saveUserData = _.merge(this.userData, data);
      await this.$axios.patch(
        "users/StoreUpdate/" + saveUserData.uid,
        saveUserData,
      );
      await this.getUserData();
      this.saveBtnLoading = false;
    },
  },
};
</script>

<style></style>

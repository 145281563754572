<template>
  <v-container
    fluid
    :class="$vuetify.breakpoint.smAndUp ? 'py-5 px-10' : 'pa-0'"
  >
    <v-card
      flat
      :class="$vuetify.breakpoint.smAndUp ? 'pa-5' : 'transparent pa-0'"
    >
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        text
        class="px-0 mb-10 grey--text text--darken-2"
        @click="$emit('changePageIndex', 10)"
      >
        <v-icon size="20"> mdi-chevron-left </v-icon>
        돌아가기
      </v-btn>
      <v-card-title
        :class="
          $vuetify.breakpoint.smAndUp
            ? 'pa-2 headline font-weight-bold'
            : 'title font-weight-bold'
        "
      >
        기업정보
      </v-card-title>
      <v-card-text
        :class="$vuetify.breakpoint.smAndUp ? 'px-7 pt-8' : 'px-2 pt-5'"
      >
        <v-form ref="form" lazy-validation>
          <v-row :style="$vuetify.breakpoint.smAndUp ? 'width: 300px' : ''">
            <v-col cols="12">
              <v-text-field
                v-model="userData.companyInfo.companyId"
                dense
                clearable
                label="사업자번호 '-'를 빼고 입력해주세요"
                :rules="[
                  (v) => !!v || '필수 항목입니다.',
                  (v) => v.length == 10 || `${v.length} / 10`,
                ]"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="userData.companyInfo.companyName"
                dense
                clearable
                label="상호"
                :rules="[(v) => !!v || '필수 항목입니다.']"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="userData.companyInfo.cheifName"
                dense
                clearable
                label="대표자명"
                :rules="[(v) => !!v || '필수 항목입니다.']"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="userData.companyInfo.class"
                dense
                clearable
                label="업태"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="userData.companyInfo.productClass"
                dense
                clearable
                label="업종"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="userData.companyInfo.email"
                dense
                clearable
                label="세금계산서 수신용 이메일"
                :rules="[
                  (v) => /.+@.+/.test(v) || '이메일 형식에 맞지 않습니다.',
                  (v) => !!v || '필수 항목입니다.',
                ]"
                required
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- 저장 -->
      <v-card-actions class="mb-15 px-7">
        <v-btn
          color="primary"
          class="px-3"
          depressed
          :loading="saveBtnLoading"
          @click="save"
          :disabled="saveValid"
        >
          저장
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "MyPage-CompanyInfo",
  props: { userData: Object, originalData: Object, saveBtnLoading: Boolean },
  data() {
    return {};
  },
  created() {},
  computed: {
    saveValid() {
      this.text_clear();
      var valid = false;
      if (
        Object.entries(this.userData.companyInfo).toString() !=
        Object.entries(this.originalData.companyInfo).toString()
      )
        valid = true;
      return !valid;
    },
  },
  methods: {
    text_clear() {
      if (this.userData.companyInfo.companyId == null)
        this.userData.companyInfo.companyId = "";
      if (this.userData.companyInfo.companyName == null)
        this.userData.companyInfo.companyName = "";
      if (this.userData.companyInfo.cheifName == null)
        this.userData.companyInfo.cheifName = "";
      if (this.userData.companyInfo.class == null)
        this.userData.companyInfo.class = "";
      if (this.userData.companyInfo.productClass == null)
        this.userData.companyInfo.productClass = "";
      if (this.userData.companyInfo.email == null)
        this.userData.companyInfo.email = "";
    },
    // 기본정보 저장
    async save() {
      if (this.userData.companyInfo.companyId) {
        if (!this.$refs.form.validate())
          return this.$toasted.global.error("입력 폼을 올바르게 작성해주세요.");
        if (
          !this.userData.companyInfo.companyName ||
          !this.userData.companyInfo.cheifName ||
          !this.userData.companyInfo.email
        )
          return this.$toasted.global.error("정보를 모두 작성해주세요.");
      }
      if (this.userData.companyInfo.companyId != "")
        this.userData.userClass = 2;
      else if (this.userData.schoolInfo.studentId != "")
        this.userData.userClass = 1;
      else this.userData.userClass = 0;
      const saveData = {
        userClass: this.userData.userClass,
        companyInfo: {
          companyId: this.userData.companyInfo.companyId,
          companyName: this.userData.companyInfo.companyName,
          cheifName: this.userData.companyInfo.cheifName,
          class: this.userData.companyInfo.class,
          productClass: this.userData.companyInfo.productClass,
          email: this.userData.companyInfo.email,
        },
      };
      this.$emit("saveData", saveData);
    },
  },
};
</script>

<style></style>

<template>
  <v-card flat class="rounded-xl pt-6">
    <!-- 본문 영역 -->
    <v-card-text>
      <v-container>
        <v-row>
          <!-- 좌측 기본정보 주문정보 패널 -->
          <v-col cols="12" sm="5">
            <v-row>
              <v-col cols="12">
                <span class="highlight title font-weight-bold onSurface--text">
                  주문 정보
                </span>
              </v-col>
              <v-col cols="4" md="2" align-self="center"> 견적 제목 </v-col>
              <v-col cols="8" md="10">
                <span class="onSurface--text">
                  {{ orderData.purpose }}
                </span>
              </v-col>
              <v-col cols="4" md="2" align-self="center"> 접수 일시 </v-col>
              <v-col cols="8" md="10">
                <span class="onSurface--text">
                  {{
                    orderData.createdAt | moment("YYYY년 MM월 DD일 HH시 mm분")
                  }}
                </span>
              </v-col>
              <v-col cols="4" md="2" align-self="center"> 진행 상황 </v-col>
              <v-col cols="8" md="10">
                <v-menu rounded="lg" bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <feather
                        size="24"
                        :type="getStatusData(orderData.status).icon"
                        :stroke="
                          getStatusData(orderData.status).color
                            ? getStatusData(orderData.status).color
                            : 'var(--v-onSurface-base)'
                        "
                      />
                    </v-btn>
                    <span
                      class="ml-2 onSurface--text"
                      v-text="getStatusData(orderData.status).title"
                    />
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(statusItem, index) in orderStatusItem"
                      :key="index"
                    >
                      <v-list-item-title class="body-2">
                        <feather
                          size="18"
                          :type="statusItem.icon"
                          :stroke="statusItem.color"
                          class="mr-2"
                        />
                        {{ statusItem.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <admin-order-status-dialog :data="orderData" />
              </v-col>
              <v-col cols="4" md="2" align-self="center"> 사용 환경 </v-col>
              <v-col cols="8" md="10">
                <span class="onSurface--text">
                  {{ orderData.environment }}
                </span>
              </v-col>
              <v-col cols="4" md="2" align-self="center">참고 사항</v-col>
              <v-col cols="8" md="10">
                <v-textarea
                  class="surface rounded-lg"
                  rows="2"
                  outlined
                  flat
                  solo
                  hide-details
                  no-resize
                  auto-grow
                  v-model="orderData.memo"
                  :readonly="true"
                >
                </v-textarea>
              </v-col>
              <v-col cols="4" md="2" align-self="center"> 희망 납기 </v-col>
              <v-col cols="8" md="10">
                <span class="onSurface--text">
                  {{ orderData.dueAt ? orderData.dueAt : "미정" }}
                </span>
              </v-col>
            </v-row>
            <div v-if="orderData.confirmed">
              <v-row>
                <v-col cols="12">
                  <span
                    class="highlight title font-weight-bold onSurface--text"
                  >
                    결제 정보
                  </span>
                </v-col>
                <v-col cols="4" md="2" align-self="center"> 결제 금액 </v-col>
                <v-col cols="8" md="10">
                  <span class="body-1 font-weight-medium onSurface--text"
                    >{{
                      orderData.productCost ? orderData.productCost : "0"
                    }}
                    KRW</span
                  >
                </v-col>
                <v-col cols="4" md="2" align-self="center"> 결제 방식 </v-col>
                <v-col cols="8" md="10">
                  <v-menu rounded="lg" bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" :disabled="true">
                        <feather
                          size="24"
                          :type="getPaymentData(orderData.paymentMethod).icon"
                          :stroke="
                            getPaymentData(orderData.paymentMethod).color
                              ? getPaymentData(orderData.paymentMethod).color
                              : 'var(--v-onSurface-base)'
                          "
                        />
                      </v-btn>
                      <span
                        class="ml-2 onSurface--text"
                        v-text="getPaymentData(orderData.paymentMethod).title"
                      />
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(Payment, index) in orderPayment"
                        :key="index"
                        @click="orderData.paymentMethod = index"
                      >
                        <v-list-item-title class="body-2">
                          <feather
                            size="18"
                            :type="Payment.icon"
                            :stroke="Payment.color"
                            class="mr-2"
                          />
                          {{ Payment.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="4" md="2" align-self="center"> 배송 방식 </v-col>
                <v-col cols="8" md="10">
                  <v-menu rounded="lg" bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" :disabled="true">
                        <feather
                          size="24"
                          :type="getShippingData(orderData.shippingMethod).icon"
                          :stroke="
                            getShippingData(orderData.shippingMethod).color
                              ? getShippingData(orderData.shippingMethod).color
                              : 'var(--v-onSurface-base)'
                          "
                        />
                      </v-btn>
                      <span
                        class="ml-2 onSurface--text"
                        v-text="getShippingData(orderData.shippingMethod).title"
                      />
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(Shipping, index) in shippingMethod"
                        :key="index"
                        @click="orderData.shippingMethod = index"
                      >
                        <v-list-item-title class="body-2">
                          <feather
                            size="18"
                            :type="Shipping.icon"
                            :stroke="Shipping.color"
                            class="mr-2"
                          />
                          {{ Shipping.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="4" md="2" align-self="center">작업 장소</v-col>
                <v-col cols="8" md="10">
                  <v-autocomplete
                    v-model="orderData.workspace"
                    hide-no-data
                    hide-details
                    solo
                    flat
                    :items="makerSpace"
                    :readonly="true"
                    label="작업장을 입력하세요"
                    :clearable="false"
                  />
                </v-col>
                <v-col cols="4" md="2" align-self="center">
                  견&nbsp;&nbsp;적&nbsp;&nbsp;서
                </v-col>
                <v-col v-if="orderData.quoteFilePath" cols="8" md="10">
                  <v-btn
                    :href="orderData.quoteDownloadUrl"
                    target="_blank"
                    color="primary"
                    style="margin: -16px"
                    class="body-2 font-weight-bold"
                    text
                  >
                    {{ orderData.quoteFilePath.split("/")[2].substr(20) }}
                  </v-btn>
                </v-col>
                <v-col v-else cols="8" md="10">
                  <span> - </span>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <!-- 우측 작업 정보 패널 -->
          <v-col cols="12" sm="7">
            <v-row>
              <v-col cols="12">
                <span class="highlight title font-weight-bold onSurface--text">
                  첨부 파일
                </span>
              </v-col>
              <v-col cols="12">
                <v-card rounded="lg" elevation="4">
                  <v-card-title
                    class="py-1"
                    style="border-bottom: 3px solid var(--v-primary-base)"
                  >
                    <span class="subtitle-2 ml-3">
                      총 {{ orderData.file ? orderData.file.length : "0" }} 개의
                      파일
                    </span>
                    <v-spacer />
                    <v-btn
                      text
                      class="mr-3 pa-0 subtitle-2"
                      @click="downloadAll(orderData.file, '견적파일')"
                    >
                      <v-icon class="mr-1" size="19">
                        mdi-tray-arrow-down
                      </v-icon>
                      전체 다운로드
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pa-4">
                    <v-virtual-scroll
                      :items="orderData.file"
                      item-height="50"
                      height="300"
                    >
                      <template v-slot:default="{ item }">
                        <v-list-item class="px-1">
                          <v-list-item-content class="pt-1">
                            <div class="px-1">
                              <v-card>
                                <v-card-title class="pa-1 d-flex text-center">
                                  <v-btn
                                    :href="item.downloadURL"
                                    target="_blank"
                                    color="primary"
                                    class="caption font-weight-medium px-2 overflow-hidden"
                                    text
                                    small
                                    max-width="300px"
                                  >
                                    {{
                                      item.filePath.split("/")[3]
                                        ? item.filePath.split("/")[3]
                                        : item.filePath.split("/")[2]
                                    }}
                                  </v-btn>
                                  <v-spacer />
                                  <span
                                    class="caption font-weight-medium px-3"
                                    style="width: 96px"
                                  >
                                    {{ getOption(item).method }}
                                  </span>
                                  <v-divider vertical />
                                  <span
                                    class="caption font-weight-medium px-3"
                                    style="width: 84px"
                                  >
                                    {{ getOption(item).material }}
                                  </span>
                                  <v-divider vertical />
                                  <span
                                    class="caption font-weight-medium px-3"
                                    style="width: 140px"
                                  >
                                    <v-icon
                                      size="15"
                                      :color="getOption(item).color.color"
                                      class="mr-2"
                                      style="
                                        border: 1px solid grey;
                                        border-radius: 50%;
                                      "
                                    >
                                      mdi-circle
                                    </v-icon>
                                    {{ getOption(item).color.name }}
                                  </span>
                                  <v-divider vertical />
                                  <span
                                    class="caption font-weight-medium px-3"
                                    style="width: 72px"
                                  >
                                    {{ item.quantity }} EA
                                  </span>
                                </v-card-title>
                              </v-card>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-virtual-scroll>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- 하단 취소 및 저장버튼 -->
    <v-card-actions class="px-4 pb-4">
      <v-spacer></v-spacer>
      <!-- 저장버튼: 화면 크기가 클 땐 일반 버튼, 작을 땐 한줄짜리 버튼 -->
      <v-btn
        color="primary"
        class="rounded-lg font-weight-bold"
        depressed
        :block="$vuetify.breakpoint.smAndDown"
        @click="$emit('closeDialog')"
      >
        닫기
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import constants from "@/utils/constants";
import AdminOrderStatusDialog from "../Admin/Order/AdminOrderStatusDialog.vue";
export default {
  components: { AdminOrderStatusDialog },
  props: {
    orderData: Object,
  },
  data() {
    return {
      orderStatusItem: constants.orderStatusItem,
      orderPayment: constants.orderPayment,
      shippingMethod: constants.shippingMethod,
      makerSpace: constants.makerSpace,
      printOption: constants.printOption,
    };
  },
  methods: {
    onCopy: function () {
      this.$toasted.global.success("클립보드에 저장했습니다.");
    },
    onCopyError: function () {
      this.$toasted.global.error("복사를 실패했습니다.");
    },
    getStatusData(statusLevel) {
      return this.orderStatusItem[statusLevel];
    },
    getPaymentData(statusLevel) {
      return this.orderPayment[statusLevel];
    },
    getShippingData(statusLevel) {
      return this.shippingMethod[statusLevel];
    },
    getOption(i) {
      return {
        method: this.printOption[i.method].method,
        material: this.printOption[i.method].material[i.material].name,
        color: this.printOption[i.method].material[i.material].color[i.color],
      };
    },
  },
};
</script>

<style></style>

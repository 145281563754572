<template>
  <v-container
    fluid
    :class="$vuetify.breakpoint.smAndUp ? 'pt-5 px-10' : 'pa-0 transparent'"
  >
    <v-card
      v-if="getOrderLoading"
      class="text-center my-15 py-15"
      flat
      color="transparent"
    >
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
      <v-card-title class="justify-center font-weight-medium title">
        주문 내역을 불러오고 있습니다.
      </v-card-title>
    </v-card>
    <v-card
      v-else
      flat
      class="transparent"
      :class="$vuetify.breakpoint.smAndUp ? 'pa-5' : 'pa-0'"
    >
      <v-card-title
        :class="$vuetify.breakpoint.smAndUp ? 'pa-2' : 'd-flex py-2'"
      >
        <span
          :class="
            $vuetify.breakpoint.smAndUp
              ? 'headline font-weight-bold'
              : 'subtitle-2 mx-auto grey--text text--darken-1'
          "
        >
          주문내역
        </span>
      </v-card-title>
      <v-card-text
        :class="$vuetify.breakpoint.smAndUp ? 'px-7 pt-8' : 'px-0 pt-3'"
      >
        <div v-if="!myOrders.length">주문 기록이 없습니다.</div>
        <div v-else>
          <div v-for="myOrders in myOrders" :key="myOrders.id">
            <v-card
              outlined
              elevation="3"
              rounded="lg"
              width="540px"
              :height="$vuetify.breakpoint.smAndUp ? '115px' : ''"
              class="mb-3"
              @click="openDialog(myOrders)"
            >
              <v-card-text
                :class="
                  $vuetify.breakpoint.smAndUp
                    ? 'px-7 pt-3 pb-4'
                    : 'px-2 pt-1 pb-2'
                "
              >
                <div class="d-flex">
                  <span class="caption">
                    {{
                      myOrders.workspace ? myOrders.workspace : "작업장 미정"
                    }}
                  </span>
                  <v-spacer />
                  <span class="caption">
                    {{ myOrders.createdAt | moment("YYYY.MM.DD") }}
                  </span>
                </div>
                <div
                  class="d-flex"
                  :class="
                    $vuetify.breakpoint.smAndUp
                      ? 'title font-weight-bold'
                      : 'subtitle-2 font-weight-bold'
                  "
                >
                  <span>
                    {{ myOrders.purpose }}
                  </span>
                  <v-spacer />
                  <span>
                    {{ myOrders.productCost ? myOrders.productCost : "0" }}
                    원
                  </span>
                </div>
              </v-card-text>
              <div
                class="d-flex caption text-center grey--text text--lighten-1"
              >
                <div class="progress" :style="progressBar(myOrders.status, 0)">
                  {{ $vuetify.breakpoint.smAndUp ? "견적접수" : "" }}
                </div>
                <div class="progress" :style="progressBar(myOrders.status, 1)">
                  {{ $vuetify.breakpoint.smAndUp ? "상담중" : "" }}
                </div>
                <div class="progress" :style="progressBar(myOrders.status, 3)">
                  {{ $vuetify.breakpoint.smAndUp ? "결제완료" : "" }}
                </div>
                <div class="progress" :style="progressBar(myOrders.status, 4)">
                  {{ $vuetify.breakpoint.smAndUp ? "작업대기" : "" }}
                </div>
                <div class="progress" :style="progressBar(myOrders.status, 5)">
                  {{ $vuetify.breakpoint.smAndUp ? "작업중" : "" }}
                </div>
                <div class="progress" :style="progressBar(myOrders.status, 6)">
                  {{ $vuetify.breakpoint.smAndUp ? "발송" : "" }}
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      class="elevation-0"
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      scrollable
      max-width="1200px"
    >
      <mypage-order-dialog :orderData="dialogData" @closeDialog="closeDialog" />
    </v-dialog>
  </v-container>
</template>

<style scoped>
.v-slider {
  background-color: red;
}
.progress {
  width: 90px;
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>

<script>
import MypageOrderDialog from "../../../components/Dialog/Users/MypageOrderDialog.vue";
export default {
  name: "MyPage-MyOrders",
  components: {
    MypageOrderDialog,
  },
  props: { userData: Object },
  data() {
    return {
      getOrderLoading: false,
      myOrders: [],

      myOrdersItemPerPage: 7,

      dialog: false,
      dialogData: {},
    };
  },
  mounted() {
    this.getOrderLoading = true;
    this.$nextTick(() => {
      this.getOrderData();
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.getOrderData();
        }
      };
    });
  },
  methods: {
    openDialog(data) {
      this.dialog = true;
      this.dialogData = data;
    },
    closeDialog() {
      this.dialog = false;
    },
    progressBar(index, step) {
      var res = "";
      if (index >= step) {
        switch (index) {
          case 0:
            res = "border-top: 5px #FED466 solid;";
            break;
          case 1:
            res = "border-top: 5px #FEC23E solid;";
            break;
          case 2:
            res = "border-top: 5px #FEC23E solid;";
            break;
          case 3:
            res = "border-top: 5px #FEA500 solid;";
            break;
          case 4:
            res = "border-top: 5px #40B6FF solid;";
            break;
          case 5:
            res = "border-top: 5px #0090FF solid;";
            break;
          case 6:
            res = "border-top: 5px #0B86E3 solid;";
            break;
        }
      } else {
        res = "border-top: 5px #E1E2E6 solid;";
      }
      var text = "";
      if (index == step || (index == 2 && step == 1))
        switch (index) {
          case 0:
            text = "color: #FED466";
            break;
          case 1:
            text = "color: #FEC23E";
            break;
          case 2:
            text = "color: #FEC23E";
            break;
          case 3:
            text = "color: #FEA500";
            break;
          case 4:
            text = "color: #40B6FF";
            break;
          case 5:
            text = "color: #0090FF";
            break;
          case 6:
            text = "color: #0B86E3";
            break;
        }

      return res + text;
    },
    async getOrderData() {
      if (this.$store.getters["auth/user/GET_USER"].uid) {
        await this.$axios
          .get("orders/getOrders", {
            params: {
              uid: this.$store.getters["auth/user/GET_USER"].uid,
              email: this.$store.getters["auth/user/GET_USER"].email,
              offset: this.myOrders.length,
              limit: this.myOrdersItemPerPage,
            },
          })
          .then((v) => {
            const data = v.data;
            data.forEach((d) => {
              this.myOrders.push(d);
            });
            this.getOrderLoading = false;
          });
      }
    },
  },
};
</script>

<template>
  <v-container fill-height class="pa-0" style="background: #f7f9fb">
    <v-card flat class="pa-0 pb-auto text-center transparent align-self-start" style="height: 700px">
      <v-card-title style="height: 66px">
        <span class="mx-auto headline font-weight-light"> M Y P A G E </span>
      </v-card-title>
      <v-divider class="mx-5"> </v-divider>
      <v-card-text class="my-6 py-5">
        <v-avatar size="64" class="d-flex mx-auto">
          <v-img :src="userData.photoURL" style="border: 2px solid #c5c5ca" />
        </v-avatar>
        <div class="mt-5 d-flex">
          <span class="mx-auto subtitle-1 font-weight-bold">
            {{ userData.displayName }}
          </span>
        </div>
        <div class="d-flex">
          <span class="mx-auto caption font-weight-light">
            {{ userData.email }}
          </span>
        </div>
      </v-card-text>
      <v-card-actions class="pb-15">
        <v-row>
          <v-btn
            :color="pageIndex == 20 ? 'primary' : 'grey'"
            class="py-6 subtitle-1 font-weight bold"
            x-large
            height="50px"
            block
            text
            @click="$emit('changePageIndex', 20)"
          >
            주문내역
          </v-btn>
          <v-btn
            :color="10 <= pageIndex && pageIndex < 20 ? 'primary' : 'grey'"
            class="py-6 subtitle-1 font-weight bold"
            x-large
            height="50px"
            block
            text
            @click="$emit('changePageIndex', 10)"
          >
            회원정보
          </v-btn>
          <v-btn
            :color="pageIndex == 10 ? 'black' : 'grey'"
            class="caption font-weight-light"
            block
            height="26px"
            text
            @click="$emit('changePageIndex', 10)"
          >
            기본정보
          </v-btn>
          <v-btn
            :color="pageIndex == 11 ? 'black' : 'grey'"
            class="caption font-weight-light"
            block
            height="26px"
            text
            @click="$emit('changePageIndex', 11)"
          >
            학생정보
          </v-btn>
          <v-btn
            :color="pageIndex == 12 ? 'black' : 'grey'"
            class="caption font-weight-light"
            block
            height="26px"
            text
            @click="$emit('changePageIndex', 12)"
          >
            기업정보
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "MyPage-SideBar",
  props: { pageIndex: Number, userData: Object },
  data() {
    return {};
  },
  methods: {},
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"elevation-0",attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"scrollable":"","max-width":"480px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.open()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-list-box-outline ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"mt-3"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"highlight title font-weight-bold onSurface--text"},[_vm._v(" 작업 내역 ")])]),_c('v-col',{attrs:{"cols":"4","md":"2","align-self":"center"}},[_vm._v(" 성함 ")]),_c('v-col',{attrs:{"cols":"8","md":"10"}},[_c('span',{staticClass:"onSurface--text"},[_vm._v(" "+_vm._s(_vm.data.displayName)+" ")])]),(this.$store.getters['auth/user/GET_CLAIMS'].level < 3)?_c('v-col',{attrs:{"cols":"4","md":"2","align-self":"center"}},[_vm._v(" ID ")]):_vm._e(),(this.$store.getters['auth/user/GET_CLAIMS'].level < 3)?_c('v-col',{attrs:{"cols":"8","md":"10"}},[_c('span',{staticClass:"onSurface--text"},[_vm._v(" "+_vm._s(_vm.data.id)+" ")]),_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.data.id),expression:"data.id",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(
                function () {
                  _vm.onCopy();
                }
              ),expression:"\n                () => {\n                  onCopy();\n                }\n              ",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(
                function () {
                  _vm.onCopyError();
                }
              ),expression:"\n                () => {\n                  onCopyError();\n                }\n              ",arg:"error"}],staticClass:"ml-2",attrs:{"small":"","color":"grey lighten-1","icon":""}},[_c('feather',{attrs:{"size":"18","type":"copy"}})],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.Loading,"headers":_vm.headers,"items":_vm.status,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"ml-2 onSurface--text",domProps:{"textContent":_vm._s(_vm.getStatusData(item.status).title)}})]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.date,"YYYY/MM/DD  HH시mm분"))+" ")])]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pa-4 pt-0"},[_c('v-spacer'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"rounded-lg",attrs:{"text":"","ripple":false},on:{"click":_vm.close}},[_vm._v(" 닫기 ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }